import React, { useCallback } from 'react';
import { ConnectionType } from '../../web3-libs/connections';
import { Option } from './Option';

const ConnectTypes = [
  {
    name: 'Connect Wallet',
    srcImg: '/images/connects/injected.svg',
    connector: 'injected',
  },
  {
    name: 'WalletConnect',
    srcImg: '/images/connects/walletconnect.svg',
    connector: 'walletconnect',
  },
];

type ConnectOptionsParams = {
  setConnector: (connectionType: ConnectionType) => void;
};

export const ConnectionOptions = ({ setConnector }: ConnectOptionsParams) => {
  const handleClickOption = useCallback(
    (connectionType: ConnectionType) => {
      setConnector(connectionType);
    },
    [setConnector]
  );
  const getOptions = () => {
    const metaMaskOption = <Option srcImg={ConnectTypes[0].srcImg} name={ConnectTypes[0].name} />;

    const walletConnectOption = (
      <Option srcImg={ConnectTypes[1].srcImg} name={ConnectTypes[1].name} />
    );

    return (
      <div className="d-flex align-items-center flex-row justify-content-center">
        <div
          style={{ width: '50%' }}
          onClick={() => {
            handleClickOption(ConnectionType.INJECTED);
          }}
        >
          {metaMaskOption}
        </div>
        <div
          style={{ width: '50%' }}
          onClick={() => {
            handleClickOption(ConnectionType.WALLET_CONNECT);
          }}
        >
          {walletConnectOption}
        </div>
      </div>
    );
  };

  return <div className="connectors">{getOptions()}</div>;
};
