import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { Connection, ConnectionType } from './connections';
import { NETWORK_CHAINS } from '../utils/chains';

const chains = NETWORK_CHAINS.map((chain) => chain.type === 'main' && chain.id);
const optionalChains = NETWORK_CHAINS.map((chain) => chain.type !== 'main' && chain.id);

export function buildWalletConnectConnector() {
  const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnectV2>(
    (actions: any) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: String(process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID),
          chains: chains.filter(Number),
          optionalChains: optionalChains.filter(Number),
          showQrModal: true,
        },
        onError: (error: Error) => {
          return error;
        },
      })
  );
  const walletConnectConnection: Connection = {
    connector: web3WalletConnect,
    hooks: web3WalletConnectHooks,
    type: ConnectionType.WALLET_CONNECT,
  };
  return walletConnectConnection;
}
