import { LENDER_RATE, SECONDS_TIME_PER_DAY } from '../utils/constants';
import { OFFER_ROLE } from '../utils/enums';
import { TOKENS } from '../utils/tokens';
import BigNumberjs from 'bignumber.js';
import { BigNumber } from 'ethers';
import { PAWN_SHOP_ADDRESS, PAWN_SHOP_ADDRESS_PROD } from '../utils/pawnShopAddress';

const waitForReceipt = async (hash, provider) => {
  try {
    return provider?.getTransactionReceipt(hash);
  } catch (e) {
    console.log(e);
    return false;
  }
};

// const createOfferHash = (data) => {
//   const dataPacked = [
//     ['bytes16', 'address', 'uint256', 'uint256', 'address', 'uint256', 'uint256'],
//     [
//       data.offerId,
//       data.collection,
//       data.tokenId,
//       data.borrowAmount,
//       data.borrowToken,
//       data.borrowPeriod,
//       data.nftAmount,
//     ],
//   ];
//   let result = ethers.utils.defaultAbiCoder.encode(...dataPacked);
//   result = ethers.utils.keccak256(result);
//   return result;
// };

const receivedAmount = (amount, serviceFee = 0, lenderFee = 0) => {
  if (amount > 0) {
    return amount - (serviceFee + lenderFee);
  }
  return 0;
};

const amountByFiat = (amount, fiatRate) => {
  if (amount > 0) {
    return amount * fiatRate;
  }
  return 0;
};

const getBorrowCycleNo = (borrowPeriod) => {
  return borrowPeriod / SECONDS_TIME_PER_DAY;
};

const checkOfferRole = (account, offer) => {
  return new Promise((resolve, reject) => {
    if (account) {
      console.log(account, offer);
      const borrower = offer?.borrower_address || '';
      const lender = offer?.lender_address || '';

      if (account.toLocaleLowerCase() === borrower.toLocaleLowerCase()) {
        return resolve(OFFER_ROLE.borrower);
      }
      if (account.toLocaleLowerCase() === lender.toLocaleLowerCase()) {
        return resolve(OFFER_ROLE.lender);
      }
    }
    return reject(OFFER_ROLE.guest);
  });
};

const estimatedInterest = (offer) => {
  const lenderFee = offer?.lenderFee ?? LENDER_RATE.min;
  let amount = (offer.amount * (lenderFee / 100)) / (365 * SECONDS_TIME_PER_DAY);
  return amount * offer.borrow_period;
};

const getTokens = (currentChainID) => {
  const tokens = Object.values(TOKENS).filter((el) => el.contracts[currentChainID]);
  let currentToken = tokens.find((el) => !el.isMainCoin);
  if (!currentToken) currentToken = tokens[0];
  return { tokens, currentToken };
};

const getCurrentToken = (tokenName) => {
  return TOKENS[tokenName];
};

const checkValidOffer = (offer) => {
  const nft = offer.nft;
  return nft?.image_url && nft?.name;
};

const convertToBigNumber = (value, decimals) => {
  let amount = new BigNumberjs(`${value}`);
  amount = amount.multipliedBy(Math.pow(10, decimals));
  const bignumberString = amount.integerValue(BigNumberjs.ROUND_DOWN).toFixed();
  return BigNumber.from(bignumberString);
};

const getPawnShopContract = (chain, networks) => {
  const blockchainName = chain.blockchainName;
  const currentNetwork = networks
    ? networks.find(
        (el) =>
          `${el.network_name}`.toLocaleLowerCase() === blockchainName || el.chain_id === chain.id
      )
    : null;
  let pawnShopContract;
  if (currentNetwork) {
    pawnShopContract = currentNetwork.pawnshop_address;
  }
  if (!pawnShopContract) {
    if (process.env.REACT_APP_ENV === 'prod') {
      pawnShopContract = PAWN_SHOP_ADDRESS_PROD[chain.id];
    } else {
      pawnShopContract = PAWN_SHOP_ADDRESS[chain.id];
    }
  }
  return pawnShopContract;
};

export {
  waitForReceipt,
  receivedAmount,
  checkOfferRole,
  getBorrowCycleNo,
  estimatedInterest,
  amountByFiat,
  getTokens,
  getCurrentToken,
  convertToBigNumber,
  getPawnShopContract,
  checkValidOffer,
};
