import React from "react";

const Footer = () => {
  return (
    <></>
    // <div className="bg-dark py-3">
    //   <div className="container">
    //     <div className="d-block d-sm-flex">
    //     </div>
    //   </div>
    // </div>
  );
};

export default Footer;
