import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ERC1155_ABI from '../abis/erc1155.json';
import ERC20_ABI from '../abis/erc20.json';
import ERC721_ABI from '../abis/erc721.json';
import LENDING_FUND_ABI from '../abis/Fund.json';
import FUND_FACTORY_ABI from '../abis/FundFactory.json';
import PAWN_SHOP_ABI from '../abis/PawnShop.json';
import { NULL_ADDRESS } from '../utils/constants';
import { RPC_URLS } from '../utils/enums';

export function getSigner(provider, account) {
  return provider.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(provider, account) {
  return account ? getSigner(provider, account) : provider;
}

export function getContract(address, ABI, provider, account) {
  return new ethers.Contract(address, ABI, getProviderOrSigner(provider, account));
}

const getInterface = (ABI) => {
  return new ethers.utils.Interface(ABI);
};

export function useERC721Interface() {
  return getInterface(ERC721_ABI);
}

const useContract = (address, ABI, withSignerIfPossible) => {
  const { provider, account } = useWeb3React();

  return useMemo(() => {
    if (!address || address === NULL_ADDRESS || !ABI) return;

    let currentProvider;
    if (!provider) {
      const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);
      currentProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);
    }
    try {
      return getContract(
        address,
        ABI,
        provider ?? currentProvider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, provider, withSignerIfPossible, account]);
};

export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useERC721Contract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC721_ABI, withSignerIfPossible);
}

export function useERC1155Contract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC1155_ABI, withSignerIfPossible);
}

export function usePawnShopContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, PAWN_SHOP_ABI, withSignerIfPossible);
}

export function useLendingFundContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, LENDING_FUND_ABI, withSignerIfPossible);
}

export function useFundFactoryContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, FUND_FACTORY_ABI, withSignerIfPossible);
}

export function useTokenPermitContract(tokenAddress) {
  const { provider, account } = useWeb3React();
  return useMemo(() => {
    if (!account || !provider) return null;
    try {
      return new ethers.Contract(
        tokenAddress,
        ['function nonces(address owner) public view virtual override returns (uint256)'],
        getProviderOrSigner(new Web3Provider(provider), account)
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [account, provider, tokenAddress]);
}

const useContractWithChain = (address, ABI, withSignerIfPossible, chainId) => {
  const { provider, account } = useWeb3React();
  const currentChain = useSelector((state) => state.chain.currentChain);

  return useMemo(() => {
    let currentProvider;
    if (currentChain.id === chainId && provider) {
      currentProvider = provider;
    } else if (chainId && RPC_URLS[parseInt(chainId)]) {
      currentProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[parseInt(chainId)]);
    }

    if (!ABI || !address || !currentProvider) return null;

    try {
      return getContract(
        address,
        ABI,
        currentProvider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [ABI, account, address, chainId, currentChain.id, provider, withSignerIfPossible]);
};

export function usePawnShopContractWithChain(tokenAddress, withSignerIfPossible, chainId) {
  return useContractWithChain(tokenAddress, PAWN_SHOP_ABI, withSignerIfPossible, chainId);
}

export function useFundContractWithChain(tokenAddress, withSignerIfPossible, chainId) {
  return useContractWithChain(tokenAddress, LENDING_FUND_ABI, withSignerIfPossible, chainId);
}

export default useContract;
