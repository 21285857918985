import { ADDED_CHAINS } from '../utils/chains';
import useConnectWallet from './useConnectWallet';

const useSwitchOrAddNetwork = () => {
  const { disconnectWallet } = useConnectWallet();
  const provider = window.ethereum;

  return async (chainId) => {
    if (!provider) return false;
    try {
      const network = ADDED_CHAINS.find((n) => n.chainId === `0x${chainId.toString(16)}`);
      if (network) {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        return true;
      } else {
        disconnectWallet();
        return false;
      }
    } catch (switchError) {
      console.log('switchError', switchError);
      if (switchError.code === 4001) return switchError; // The request was rejected by the user
      // This error code indicates that the chain has not been added to MetaMask.
      const network = ADDED_CHAINS.find((n) => n.chainId === `0x${chainId.toString(16)}`);
      console.log('network', network, switchError, switchError.code);
      if (
        (switchError.code === 4902 ||
          `${switchError}`.includes('is not exist') ||
          `${JSON.stringify(switchError)}`.includes('wallet_addEthereumChain')) &&
        network
      ) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [network],
          });
        } catch (addError) {
          if (addError.code === 4001) return false; // The request was rejected by the user
          // handle "add" error
          console.log(addError);
        }
      }
      return {
        message: switchError?.message,
        code: switchError.code,
      };
      // handle other "switch" errors
    }
  };
};

export default useSwitchOrAddNetwork;
