import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { useWeb3React } from '@web3-react/core';
import { registerEmailApi, registerEmailStatusApi } from '../../apis/notificationApis';
import signMessage from '../../hooks/useSignMessage';
import { authUpdateOpenDropEmail } from '../../redux/slices/authSlice';
import store from '../../redux/store';
import './EmailNotification.scss';
import { REDUCERS } from '../../utils/enums';

const EmailNotification = () => {
  const { provider, account } = useWeb3React();
  const openDropEmail = useSelector((state) => state.auth.openDropEmail);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [emailStatus, setEmailStatus] = useState(false);
  const [email, setEmail] = useState('');
  const connector = useSelector((state) => state[REDUCERS.CONFIG].currentConnector);

  useEffect(() => {
    if (account) checkEmailRegisterStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (openDropEmail) {
      if (!dropdownOpen) {
        setEmailStatus(false);
        setDropdownOpen(openDropEmail);
      }
      store.dispatch(authUpdateOpenDropEmail(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropEmail]);

  const checkEmailRegisterStatus = async () => {
    const res = await registerEmailStatusApi(account);
    setEmailStatus(res?.is_email_registed);
  };

  const toggle = () => {
    if (!dropdownOpen) resetMessages();
    setDropdownOpen(!dropdownOpen);
  };

  const onUpdateEmail = (e) => {
    resetMessages();
    setEmail(e.target.value);
  };

  const resetMessages = () => {
    if (errorMessage) setErrorMessage();
    if (successMessage) setSuccessMessage();
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return !(regex.test(email) === false);
  };

  const onRegisterEmail = async () => {
    resetMessages();
    if (!email) {
      setErrorMessage('Please input your email');
      return;
    }
    const isValidEmail = emailValidation(email);
    if (!isValidEmail) {
      setErrorMessage('Email address not valid!');
      return;
    }
    try {
      const signature = await signMessage(provider, account, email);
      if (signature) registerEmail(email, signature);
    } catch (e) {
      console.log(e?.message);
    }
  };

  const registerEmail = async (email, signature) => {
    try {
      const params = {
        email,
        signature: signature,
      };
      const res = await registerEmailApi(account, params);
      if (res) {
        setSuccessMessage('Register email successfully!');
        setEmail('');
        setEmailStatus(true);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage('Register email failed!');
    }
  };

  return (
    <>
      <Dropdown className="email-notification" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="email-notification__toggle">
          <IconEmail dropdownOpen={dropdownOpen} />
        </DropdownToggle>
        <div>
          <DropdownMenu right className="email-notification__menu p-3">
            <span className="text-caption-bold">
              Add your email to get the market notifications
            </span>
            <div className="my-2">
              <Input
                type="email"
                className="email-notification__input"
                value={email}
                onChange={onUpdateEmail}
                placeholder={emailStatus ? '******************************' : ''}
              />
              <div className="mt-1">
                {errorMessage && <span className="text-body-2 text-danger">{errorMessage}</span>}
                {successMessage && (
                  <span className="text-body-2 text-success">{successMessage}</span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="m-0 border-0 text-caption-bold d-flex align-items-center"
                color="primary"
                outline
                type="button"
                size="sm"
                block
                onClick={onRegisterEmail}
              >
                {connector && (
                  <span className="ic-connector">
                    <img src={`/images/connects/${connector}.svg`} alt={connector} />
                  </span>
                )}
                <span
                  style={{
                    marginTop: '2px',
                    textTransform: 'capitalize',
                  }}
                >
                  {emailStatus ? 'Update Email' : 'Submit'}
                </span>
              </Button>
            </div>
          </DropdownMenu>
        </div>
      </Dropdown>
    </>
  );
};

export default EmailNotification;

function IconEmail(props) {
  const emailFill = props.dropdownOpen ? '#28AFEC' : '#576E86';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={emailFill}
        d="M18.75 5H4.75C3.7875 5 3.00875 5.7875 3.00875 6.75L3 17.25C3 18.2125 3.7875 19 4.75 19H18.75C19.7125 19 20.5 18.2125 20.5 17.25V6.75C20.5 5.7875 19.7125 5 18.75 5ZM18.75 17.25H4.75V8.5L11.75 12.875L18.75 8.5V17.25ZM11.75 11.125L4.75 6.75H18.75L11.75 11.125Z"
      />
    </svg>
  );
}