import React, { FC } from 'react';
interface OptionProps {
  srcImg: string;
  name: string;
}

export const Option: FC<OptionProps> = ({ srcImg, name }) => {
  return (
    <div className="d-flex align-items-center connect">
      <div className="ic-connect">
        <img src={srcImg} alt={name} />
      </div>
      <span className="title-connect text-body-1-bold">{name}</span>
    </div>
  );
};
