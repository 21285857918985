import { Web3ReactHooks } from '@web3-react/core';
import { Connector, AddEthereumChainParameter } from '@web3-react/types';
import { buildInjectedConnector } from './injected';
import { buildWalletConnectConnector } from './wallet-connect';
import { ADDED_CHAINS, KNOWN_CHAINS } from '../utils/chains';
import { handleShowMessage } from '../helpers/error-helper';

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

export enum ConnectionType {
  INJECTED = 'injected',
  WALLET_CONNECT = 'walletconnect',
}

export interface NativeCurrency {
  name: string;
  symbol: string;
  decimals: 18;
}

export const PRIORITIZED_CONNECTORS: { [key in ConnectionType]: Connection } = {
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  [ConnectionType.WALLET_CONNECT]: buildWalletConnectConnector(),
};

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error('Unsupported Connector');
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return PRIORITIZED_CONNECTORS[ConnectionType.INJECTED];
      case ConnectionType.WALLET_CONNECT:
        return PRIORITIZED_CONNECTORS[ConnectionType.WALLET_CONNECT];
    }
  }
}

export const switchNetwork = async (chainId: number, connectionType: ConnectionType | null) => {
  if (!connectionType) {
    return;
  }
  const { connector }: any = getConnection(connectionType);

  if (connectionType === ConnectionType.WALLET_CONNECT) {
    try {
      await connector.activate(chainId);
    } catch (error) {
      if (error && String(error).includes('as the wallet is not connected to it')) {
        const errMessage = `The ${
          KNOWN_CHAINS.get(chainId)?.fullName
        } network does not exist in the wallet.
          Please add it to the wallet, and try again.
        `;
        handleShowMessage('error', errMessage);
      }
    }
    return;
  }

  const chainInfo = ADDED_CHAINS.find((n) => n.chainId === `0x${chainId.toString(16)}`);
  const addChainParameter: AddEthereumChainParameter = {
    chainId,
    chainName: chainInfo?.chainName || '',
    rpcUrls: chainInfo?.rpcUrls || [],
    nativeCurrency: chainInfo?.nativeCurrency as NativeCurrency,
    blockExplorerUrls: chainInfo?.blockExplorerUrls,
  };
  await connector.activate(addChainParameter);
};

export const tryActivateConnector = async (
  connector: Connector,
  chainId?: number
): Promise<ConnectionType | undefined> => {
  try {
    await connector.activate();
    await connector.activate(chainId);
    const connectionType = getConnection(connector).type;
    return connectionType;
  } catch (error: any) {}
};

export const tryDeactivateConnector = async (connector: Connector): Promise<null | undefined> => {
  connector.deactivate?.();
  connector.resetState();
  return null;
};
