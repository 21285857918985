export const PAWN_SHOP_ADDRESS = {
  4: '0x5F7E0A30A3D99FdD3ecEB9Cd96D7bCB151AA33a6',
  97: '0x72eE69CB8EB69eaeAFbb67dB048C65DFE4f3e061',
  336: '0x05E803aE8488F258A5179594c154644512F5305f',
  80001: '0x45a24cC976D7405fbd9Ab751af9c5B224EAf1c6d',
  592: '0xd7E22afb7BFd8D04e025fA34B65e306F47f606D2',
};

export const PAWN_SHOP_ADDRESS_PROD = {
  336: '0x6FADba92a82FEaF0ee0347223bE45c7D7630Dc4d',
  1: '',
  56: '0x4537127A73D719Bcbb9BEB5b8d7f6477a54f7B66',
  137: '',
  592: '0x313B92C49a786eC0ae53ce15b44b0dEAbC6b60FF',
};
