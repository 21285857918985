import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useTokenContract } from '../../../hooks/useContract';
import { convertToNumber, formatCoin, formatFiatAmount } from '../../../utils/formats';
import { pollEvery } from '../../../utils/poll';

const POLL_BALANCE_INTERVAL = 1000 * 5 * 60;

const AccountTokenBalance = ({
  account,
  token,
  chainId,
  onBalanceChange,
  styleBalance = '',
  rate,
  fiat,
}) => {
  const erc20 = useTokenContract(token.contracts[chainId], true);
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState();
  const stopPollingBalance = useRef();

  useEffect(() => {
    if (!erc20 || !account || !token) return;
    if (stopPollingBalance.current) stopPollingBalance.current();
    let cancel = false;
    const pollBalance = pollEvery((onUpdate) => {
      let lastBalance = 0;
      return {
        async request() {
          setLoading(true);
          try {
            const balance = await erc20.balanceOf(account);
            setLoading(false);
            if (balance) return parseFloat(convertToNumber(balance, token.decimals));
            return null;
          } catch (e) {
            setLoading(false);
          }
        },
        onResult(balance) {
          if (!cancel && balance != null && lastBalance !== balance) {
            lastBalance = balance;
            onUpdate(balance);
            if (onBalanceChange) onBalanceChange(balance);
          }
        },
      };
    }, POLL_BALANCE_INTERVAL);

    stopPollingBalance.current = pollBalance(setBalance);

    return () => {
      cancel = true;
      stopPollingBalance.current();
      setBalance(null);
      if (onBalanceChange) onBalanceChange(null);
    };
  }, [erc20, account, token, onBalanceChange]);

  return (
    <span>
      {loading ? (
        <Spinner size="sm" color="black" />
      ) : (
        <span className="d-flex align-items-center">
          <span className={styleBalance}>{balance ? formatCoin(balance) : 0}</span>
          {rate > 0 && balance > 0 ? (
            <span className="text-caption ml-1">({formatFiatAmount(balance * rate, fiat)})</span>
          ) : null}
        </span>
      )}
    </span>
  );
};

export default AccountTokenBalance;
