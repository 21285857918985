import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ConnectionOptions } from '../../../Web3React/ConnectOptions';

const ChooseWalletModal = ({ isOpen, toggle, setConnector }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        size={'sm'}
        backdrop={'static'}
        className="choose-wallet-modal"
      >
        <ModalHeader className="text-capitalize action-header">
          <div className="bg-white d-flex align-items-center justify-content-between">
            <div className="text-title">Connect a wallet</div>
            <Button className="m-0 btn-close p-0" color="link" onClick={toggle} tag="a">
              &times;
            </Button>
          </div>
        </ModalHeader>
        <ModalBody className="p-3">
          <ConnectionOptions setConnector={setConnector} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChooseWalletModal;
