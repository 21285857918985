import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChainId, setAccounts } from '../redux/slices/configSlice';
import { REDUCERS, CONNECTORS } from '../utils/enums';

const useNetworkListener = () => {
  const provider = window?.ethereum;

  const dispatch = useDispatch();
  const currentChainId = useSelector((state) => state[REDUCERS.CHAIN].currentChainID);

  const connector = useSelector((state) => state[REDUCERS.CONFIG].currentConnector);
  const chains = useSelector((state) => state[REDUCERS.CHAIN].data);
  const NETWORK_CHAIN_IDS = chains.map((el) => +el.id);
  const [chainMetaMaskId, setChainMetaMaskId] = useState(null);

  useEffect(() => {
    if (!provider) return;

    const updateChainId = (chainId) => {
      if (connector === CONNECTORS.walletconnect) return;
      setChainMetaMaskId(chainId);
      if (NETWORK_CHAIN_IDS.includes(chainId)) {
        dispatch(setChainId(chainId));
      } else if (!currentChainId) {
        dispatch(setChainId(NETWORK_CHAIN_IDS[0]));
      }
    };

    const getMetamaskChainId = async () => {
      const chainId = await provider.request({ method: 'eth_chainId' });
      if (chainId) updateChainId(parseInt(chainId, 16));
    };

    getMetamaskChainId();

    provider.on('chainChanged', function (networkId) {
      console.log('chainChanged');
      updateChainId(parseInt(networkId, 16));
    });

    provider.on('accountsChanged', function (accounts) {
      if (connector !== CONNECTORS.injected) return;
      console.log('accounts', accounts);
      dispatch(setAccounts(accounts));
      if (accounts.length <= 0) {
        localStorage.removeItem('isConnected');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { chainMetaMaskId };
};

export default useNetworkListener;
