import { useWeb3React } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { authResetInfo, authUpdateInfo } from '../redux/slices/authSlice';
import { useHistory } from 'react-router-dom';
import { updateCurrentChain } from '../redux/slices/chainSlice';
import { setChainId, setCurrentConnector } from '../redux/slices/configSlice';
import store from '../redux/store';

import { CONNECTORS, REDUCERS } from '../utils/enums';
import {
  getConnection,
  tryActivateConnector,
  tryDeactivateConnector,
} from '../web3-libs/connections';

const useConnectWallet = () => {
  const { chainId, account, isActive, connector } = useWeb3React();
  const NETWORK_CHAINS = useSelector((state) => state[REDUCERS.CHAIN].data);
  const [isLogged, setIsLogged] = useState(false);
  const [connectorMounted, setConnector] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const currentConnector = useSelector((state) => state[REDUCERS.CONFIG].currentConnector);
  const NETWORK_CHAIN_IDS = useMemo(() => {
    return NETWORK_CHAINS.map((el) => +el.id);
  }, [NETWORK_CHAINS]);

  const disconnectWallet = useCallback(async () => {
    try {
      await tryDeactivateConnector(getConnection(currentConnector).connector);
      setIsLogged(false);
      setConnector(null);
      localStorage.removeItem('isConnected');
      localStorage.removeItem('connector');
      localStorage.removeItem('walletconnect');
      store.dispatch(setCurrentConnector());
      store.dispatch(authResetInfo());
    } catch (err) {
      setError(err);
    }
  }, [currentConnector]);

  const handleChainId = useCallback(
    (chainID) => {
      localStorage.setItem('currentChainID', chainID);
      store.dispatch(setChainId(chainID));
      const chainObj = NETWORK_CHAINS.find((el) => el.id === chainID);
      const query = new URLSearchParams(window.location.search);
      if (query.get('network')) {
        query.set('network', chainObj.blockchainName);
        history.replace({ search: query.toString() });
      }
      store.dispatch(updateCurrentChain(chainObj));
    },
    [NETWORK_CHAINS, history]
  );

  useEffect(() => {
    if (!isActive && isLogged && currentConnector) {
      disconnectWallet();
    }
  }, [currentConnector, disconnectWallet, isActive, isLogged]);

  useEffect(() => {
    if (isActive) {
      localStorage.setItem('isConnected', JSON.stringify(true));
      let connectType;
      if (connector instanceof MetaMask) connectType = CONNECTORS.injected;
      if (connector instanceof WalletConnect) connectType = CONNECTORS.walletconnect;
      if (connectType) {
        setConnector(connectType);
        localStorage.setItem('connector', connectType);
        store.dispatch(setCurrentConnector(connectType));
      }
      setIsLogged(true);
    }
  }, [connector, isActive]);

  const connectWallet = async (connector) => {
    try {
      const localStorageChain = localStorage.getItem('currentChainID') ?? '0';
      const currentChainID = Number(localStorageChain);
      const result = await tryActivateConnector(getConnection(connector).connector, currentChainID);
      setConnector(result);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (isActive) {
      if (NETWORK_CHAIN_IDS.includes(chainId)) {
        handleChainId(chainId);
      } else {
        disconnectWallet();
      }
    }
  }, [NETWORK_CHAIN_IDS, chainId, disconnectWallet, handleChainId, isActive]);

  useEffect(() => {
    if (account) store.dispatch(authUpdateInfo({ address: account }));
  }, [account]);

  return { disconnectWallet, connectWallet, isLogged, connectorMounted, error, setError };
};

export default useConnectWallet;
