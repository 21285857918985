import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { addressShorten, convertToNumber, formatCoin } from '../../../../utils/formats';
import { TOKENS } from '../../../../utils/tokens';
import AccountTokenBalance from '../AccountTokenBalance';
import './AccountState.scss';
import { RPC_URLS, SCREENS } from '../../../../utils/enums';
import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import store from '../../../../redux/store';
import { setCurrentConnector } from '../../../../redux/slices/configSlice';

const AccountState = ({ disconnect }) => {
  const currentChain = useSelector((state) => state.chain.currentChain);
  const chainId = currentChain.id;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadBalance, setLoadBalance] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [mainCoin, setMainCoin] = useState(null);
  const [mainBalance, setMainBalance] = useState(0);
  const { account } = useWeb3React();
  const isMounted = React.useRef(true);
  const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);

  useEffect(() => {
    if (chainId) {
      const list = [];
      Object.keys(TOKENS).forEach((key) => {
        const el = TOKENS[key];
        const tokenAddress = el.contracts[chainId];
        if (tokenAddress) {
          list.push(el);
          if (el.isMainCoin) setMainCoin(el);
        }
      });
      setTokens(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, TOKENS]);

  useEffect(() => {
    if (mainCoin && dropdownOpen) {
      getBalanceMainCoin(mainCoin);
    }
    return () => (isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCoin, dropdownOpen]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getBalanceMainCoin = async (token) => {
    setLoadBalance(true);
    fetchBalance(token);
  };

  const fetchBalance = async (token) => {
    try {
      const resultFetchBalance = await provider.getBalance(account);
      let result = convertToNumber(resultFetchBalance, token.decimals);
      if (!account) return;
      setMainBalance(parseFloat(result));
      setLoadBalance(false);
    } catch (error) {
      if (!account) return;
      setMainBalance(0);
      setLoadBalance(false);
    }
  };

  const handleDisconnect = useCallback(() => {
    disconnect();
    store.dispatch(setCurrentConnector());
  }, [disconnect]);

  return (
    <Dropdown className="address-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="address-btn">{addressShorten(account)}</DropdownToggle>
      {dropdownOpen && (
        <DropdownMenu className="address-dropdown__menu" right>
          {tokens.map((el, id) => {
            let tokenBalance;
            if (!el.isMainCoin) {
              tokenBalance = <AccountTokenBalance account={account} chainId={chainId} token={el} />;
            } else {
              tokenBalance = <> {loadBalance ? <Spinner size="sm" /> : formatCoin(mainBalance)}</>;
            }
            return (
              <DropdownItem text key={`${el}-${id}`} className="pb-2">
                <div className="d-flex align-items-center">
                  <img className="icon-coin mr-3" src={`/images/coins/${el.name}.svg`} alt="icon" />
                  <div className="d-flex flex-column text-uppercase">
                    <div className="coin-name">{el.name}</div>
                    <div>{tokenBalance}</div>
                  </div>
                </div>
              </DropdownItem>
            );
          })}

          <DropdownItem>
            {currentChain && currentChain.blockchainName && (
              <Link to={`/${SCREENS.DASHBOARD}`}>
                <div className="d-flex align-items-center">
                  <img
                    className="mr-2 icon-dashboard"
                    src="/images/icons/ic-dashboard.svg"
                    alt="My Dashboard"
                  />
                  <span className="title-link">My Dashboard</span>
                </div>
              </Link>
            )}
          </DropdownItem>
          <DropdownItem onClick={handleDisconnect}>
            <div className="d-flex align-items-center">
              <img src="/images/icons/log-out.svg" className="mr-2 icon-signout" alt="Sign Out" />
              <span className="title-link">Sign Out</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default AccountState;
