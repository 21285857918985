import { BASE_URL } from '../utils/constants';
import axiosClient from './axiosClient';

export const getTotalLendingApi = (params) => {
  return axiosClient
    .get(`${BASE_URL.public}offers/total_lending`, {
      params,
    })
    .then((res) => res.data);
};
