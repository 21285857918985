import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  selectedFund: null,
  balance: 0,
};

const fundSlice = createSlice({
  name: 'fund',
  initialState: INIT_STATE,
  reducers: {
    updateSelectedFund(state, action) {
      state.selectedFund = action.payload;
    },
    updateBalanceSelectedFund(state, action) {
      state.balance = action.payload;
    },
  },
});

export const { updateSelectedFund, updateBalanceSelectedFund } = fundSlice.actions;

export default fundSlice.reducer;
