import { createSlice } from '@reduxjs/toolkit';
import useSupportedNetworks from '../../hooks/useSupportedNetworks';
import { REDUCERS } from '../../utils/enums';

const INITIAL_STATE = {
  data: [],
  currentChain: {},
  currentChainID: process.env.REACT_APP_CHAIN_ID,
  isSupportedFund: false,
};

const chainSlice = createSlice({
  name: REDUCERS.CHAIN,
  initialState: INITIAL_STATE,
  reducers: {
    loadChainsSuccess(state, action) {
      state.data = action.payload;
    },
    updateCurrentChain(state, action) {
      state.currentChain = action.payload;
      state.currentChainID = action.payload.id;
    },
  },
});

export const { loadChainsSuccess, loadChainStart, updateCurrentChain } = chainSlice.actions;

export const loadChainsRequest = () => async (dispatch) => {
  const { supportedNetworks } = useSupportedNetworks();
  supportedNetworks.sort((a, b) => a.id - b.id);

  let chainID =
    localStorage.getItem('currentChainID') && JSON.parse(localStorage.getItem('currentChainID'))
      ? JSON.parse(localStorage.getItem('currentChainID'))
      : parseInt(process.env.REACT_APP_CHAIN_ID);
  if (supportedNetworks.length > 0) {
    dispatch(loadChainsSuccess(supportedNetworks));
    let chainItem = supportedNetworks.find((el) => el.id === chainID && el.isSupported);
    if (!chainItem) chainItem = supportedNetworks.find((el) => el.isSupported);
    dispatch(updateCurrentChain(chainItem));
    localStorage.setItem('currentChainID', JSON.stringify(chainItem.id));
  }
};

export default chainSlice.reducer;
