import React, { ReactNode, useCallback, useEffect } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { ConnectionType, PRIORITIZED_CONNECTORS, getConnection } from '../../web3-libs/connections';
import { Connector } from '@web3-react/types';

export const Web3ContextProvider = ({ children }: { children: ReactNode }) => {
  const connect = useCallback(async (connector: Connector) => {
    try {
      if (connector.connectEagerly) {
        await connector.connectEagerly();
      } else {
        await connector.activate();
      }
    } catch (error) {
      console.debug(`web3-react eager connection error: ${error}`);
    }
  }, []);

  const connectEagerly = useCallback(async () => {
    await connect(getConnection(ConnectionType.WALLET_CONNECT).connector);
  }, [connect]);

  useEffect(() => {
    connectEagerly();
  }, [connectEagerly]);

  return (
    <Web3ReactProvider
      connectors={Object.values(PRIORITIZED_CONNECTORS).map((connector) => [
        connector.connector,
        connector.hooks,
      ])}
    >
      {children}
    </Web3ReactProvider>
  );
};
