export const SCREENS = Object.freeze({
  NOT_FOUND: '404',
  CREATE_OFFER: 'create-offer',
  OFFER_DETAIL: 'offer',
  MY_PROFILE: 'my-profile',
  PROFILE: 'profile',
  DASHBOARD: 'dashboard',
  LENDING_FUND: 'lending-fund',
  CREATE_LENDING_FUND: 'create-lending-fund',
});
export const REDUCERS = Object.freeze({
  RATE: 'rate',
  AUTH: 'auth',
  CHAIN: 'chain',
  NETWORK: 'network',
  CONFIG: 'config',
});

export const FORMAT_DATE = Object.freeze({
  fullDate: '',
});

export const OFFER_STATUS = Object.freeze({
  cancelled: 'cancelled',
  pending: 'pending',
  inprogress: 'inprogress',
  completed: 'completed',
  open: 'open',
  completed_by_repaid: 'completed_by_repaid',
  completed_by_claimed: 'completed_by_claimed',
});

export const OFFER_ROLE = Object.freeze({
  guest: 'guest',
  admin: 'admin',
  lender: 'lender',
  borrower: 'borrower',
});

export const DATE_TIME_FORMATS = Object.freeze({
  DATE_FORMAT: 'MM/dd/yyyy',
  TIME_FORMAT: 'HH:mm',
  FULL_TIME_FORMAT: 'HH:mm:ss',
  DATE_TIME_FORMAT: 'HH:mm MM/dd',
  FULL_DATE_TIME_FORMAT: 'HH:mm MM/dd/yyyy',
  TIME_TODAY_FORMAT: 'HH:mm [today]',
});

export const CHAIN_FULL_NAME = Object.freeze({
  bsc: 'Binance Smart Chain',
  ethereum: 'Ethereum',
  polygon: 'Polygon',
  astar: 'Astar',
});

export const FIAT_SYMBOL = Object.freeze({
  usd: '$',
});

export const STATUS_TEXT = Object.freeze({
  cancelled: 'cancelled',
  pending: 'pending',
  inprogress: 'lending',
  completed_by_repaid: 'repaid',
  completed_by_claimed: 'liquidated',
  open: 'waiting for an application',
});

export const SUPPORTED_TOKENS = Object.freeze({
  USDT: true,
  BNB: true,
  ETH: false,
  USDC: true,
  SDN: true,
  MATIC: true,
  ASTR: true,
});

export const ETH_CHAINS = Object.freeze([1, 3, 4]);
export const RPC_URLS = Object.freeze({
  56: 'https://bsc-dataseed1.ninicoin.io/',
  97: 'https://data-seed-prebsc-2-s1.binance.org:8545',
  336: 'https://rpc.shiden.astar.network:8545',
  137: 'https://polygon.llamarpc.com',
  80001: 'https://matic-mumbai.chainstacklabs.com',
  592: 'https://evm.astar.network', // Astar,
  1: '',
  4: 'https://rinkeby.infura.io/v3/2919ba76d1cc4c58a3649de1a7222768',
});

export const CONNECTORS = Object.freeze({
  injected: 'injected',
  walletconnect: 'walletconnect',
});
