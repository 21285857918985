import { createSlice } from '@reduxjs/toolkit';
import { getNetwrokApi } from '../../apis/networkApis';
import { REDUCERS } from '../../utils/enums';

const INITIAL_STATE = {
  loading: false,
  networks: [],
  pawnShopContract: undefined,
};

const networkSlice = createSlice({
  name: REDUCERS.NETWORK,
  initialState: INITIAL_STATE,
  reducers: {
    loadNetworkSuccess(state, action) {
      state.networks = action.payload;
    },
    loadNetworkStart(state) {
      state.loading = true;
    },
    updatePawnShopContract(state, action) {
      state.pawnShopContract = action.payload;
    },
  },
});

export const { loadNetworkSuccess, loadNetworkStart, updatePawnShopContract } =
  networkSlice.actions;

export const loadNetworksRequest = () => async (dispatch, getState) => {
  const state = getState();

  if (state.network.loading) return;
  dispatch(loadNetworkStart());
  const result = await getNetwrokApi();
  if (result) dispatch(loadNetworkSuccess(result));
};

export default networkSlice.reducer;
