import { format, isDate } from 'date-fns';
import { NFT_PLATFORMS } from './constants';
import { FIAT_SYMBOL } from './enums';
import { ethers, BigNumber } from 'ethers';
import BigNumberjs from 'bignumber.js';

const uuidToHex = require('uuid-to-hex');

export const addressShorten = (address, num) => {
  if (!address) return '';
  if (!num) num = 6;
  if (num >= address.length / 2) return address;
  const prefix = address.slice(0, num);
  const suffix = address.slice(-num, address.length);
  return `${prefix}...${suffix}`;
};

export function numberFormat(num) {
  return `${num.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 18,
  })}`;
}

export const isNumeric = (value) => /^-?\d+$/.test(value) || /[.]/.test(value);

export const formatFiatAmount = (value, fiat) => {
  return `${FIAT_SYMBOL[fiat]}${formatNumber(value)}`;
};

export const formatNumber = (value, currency, decimal) => {
  if (!decimal && decimal < 0) decimal = 2;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimal,
  });
  return `${formatter.format(value)}${currency ? ' ' + currency.toUpperCase() : ''}`;
};

export const formatCoin = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
  });
  return `${formatter.format(value)}`;
};

export function calcFee(fee, amount, fiat) {
  const value = (fee * amount) / 100;
  return `${formatNumber(value, fiat)}`;
}

export function getUrlInfo(url) {
  if (!url) return {};
  if (
    url.includes('bscscan.com') ||
    url.includes('etherscan.io') ||
    url.includes('polygonscan.com')
  ) {
    url = url.replace('?a=', '/');
  }
  const pathname = new URL(url).pathname;
  let arrPath = pathname
    .replace(':', '/')
    .split('/')
    .filter((el) => el);
  arrPath = arrPath.reverse();
  let platform = new URL(url);
  platform = platform.hostname.replace('www.', '');
  const arrStrPlatform = platform.split('.');
  platform = arrStrPlatform.find((el) => NFT_PLATFORMS.includes(el));
  return {
    platform,
    tokenId: arrPath[0],
    collectionContractAddress:
      arrPath[1] && ethers.utils.getAddress(arrPath[1]) ? arrPath[1] : null,
  };
}

export const descriptionShorten = (description, num) => {
  if (!num) num = 300;
  if (num >= description.length / 2) return description;
  const prefix = description.slice(0, num);
  return `${prefix}...`;
};

export const convertUuidToHex = (id) => {
  try {
    return uuidToHex(id, true);
  } catch {
    return null;
  }
};

export const formatDate = (date, type) => {
  const val = !isDate(date) ? new Date(date) : date;
  return format(val, type);
};

export const bignumberToNumber = (amount, multiplier) => {
  try {
    const val = BigNumber.from(amount).toNumber();
    return val / multiplier;
  } catch (error) {
    const result = ethers.utils.formatEther(amount);
    return parseFloat(result);
  }
};

export const formatDateIOS = (date) => {
  const val = date ? new Date(date) : null;
  return val ? val.toISOString().slice(0, -5) + 'Z' : '';
};

export const convertToNumber = (value, decimals) => {
  let amount = new BigNumberjs(`${value}`);
  amount = amount.dividedBy(Math.pow(10, decimals));
  return parseFloat(amount);
};

export const convertIpfsUrl = (imgUrl) => {
  if (!imgUrl) {
    return '/images/default-nft.png';
  } else if (imgUrl.startsWith('https://ipfs.io')) {
    return imgUrl.replace('ipfs.io', 'ipfs.wakumo.net');
  } else if (imgUrl.startsWith('ipfs://ipfs/')) {
    return imgUrl.replace('ipfs://ipfs/', 'https://ipfs.wakumo.net/ipfs/');
  } else if (imgUrl.startsWith('ipfs://')) {
    return imgUrl.replace('ipfs://', 'https://ipfs.wakumo.net/ipfs/');
  } else if (imgUrl.includes('genesis')) {
    return imgUrl.replace('genesis', 'app');
  }
  return imgUrl;
};

export const formatText = (value) => {
  return value.replace(/\\n|\n/g, '<br />');
};

export const bignumberMultiplier = (decimals) => {
  return BigNumber.from(10).pow(decimals);
};

export const millisecondsToDate = (time) => {
  return new Date(+time);
};

export const formatFundBalance = (balance, decimal) => {
  if (+formatNumber(balance) <= 0) return +formatNumber(balance);
  if (!decimal) decimal = 4;
  const arr = `${balance}`.split('.');
  const result1 = arr[0];
  let result2 = 0;
  if (arr.length === 2) {
    result2 = arr[1];
    if (arr[1].length > decimal) result2 = arr[1].slice(0, decimal);
  }
  return parseFloat(`${result1}.${result2}`);
}
