import { createSlice } from '@reduxjs/toolkit';
import { getExchangeRatesApi } from '../../apis/rateApis';
import { REDUCERS } from '../../utils/enums';

const INITIAL_STATE = {
  loading: false,
  data: {},
};

const rateSlice = createSlice({
  name: REDUCERS.RATE,
  initialState: INITIAL_STATE,
  reducers: {
    loadRatesSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    loadRatesStart(state) {
      state.loading = true;
    },
  },
});

export const { loadRatesSuccess, loadRatesStart } = rateSlice.actions;

export const loadRatesRequest = () => async (dispatch, getState) => {
  const state = getState();
  if (state.rate.loading) return;
  dispatch(loadRatesStart());
  const result = await getExchangeRatesApi();
  if (result) dispatch(loadRatesSuccess(result));
};

export default rateSlice.reducer;
