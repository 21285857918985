export const TOKENS = {
  USDC: {
    isMainCoin: false,
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    contracts: {
      4: '0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b',
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      80001: '0x8A6E491EeAc69BbC9fD7102432d751Bfbf038c26',
      592: '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
    },
    floatingPoint: 3,
  },
  USDT: {
    isMainCoin: false,
    name: 'USDT',
    symbol: 'USDT',
    decimals: 18,
    contracts: {
      4: '0xa4704058be61F0f2b613e50B1a8264ebF82a3f1E',
      97: '0x46b220462b5Fe5bD397b4490DBDe6Fa9B4e96B87',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    floatingPoint: 3,
  },
  ETH: {
    isMainCoin: true,
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    contracts: {
      4: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    },
    floatingPoint: 4,
  },
  BNB: {
    isMainCoin: true,
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
    contracts: {
      97: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      56: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    },
    floatingPoint: 4,
  },
  SDN: {
    isMainCoin: true,
    name: 'SDN',
    symbol: 'SDN',
    decimals: 18,
    contracts: {
      336: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    },
    floatingPoint: 4,
  },
  MATIC: {
    isMainCoin: true,
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
    contracts: {
      137: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      80001: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    },
    floatingPoint: 4,
  },
  ASTR: {
    isMainCoin: true,
    name: 'ASTR',
    symbol: 'ASTR',
    decimals: 18,
    contracts: {
      592: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    },
    floatingPoint: 4,
  },
};