import { BASE_URL } from '../utils/constants';
import axiosClient from './axiosClient';

export const registerEmailApi = (account, params) => {
  return axiosClient.put(`${BASE_URL.public}users/${account}`, params).then((res) => res.data);
};

export const registerEmailStatusApi = (account) => {
  return axiosClient.get(`${BASE_URL.public}users/${account}`).then((res) => res.data);
};

export const unsubscribeEmailApi = (address, params) => {
  return axiosClient.post(`${BASE_URL.public}users/${address}/unsubscribe`, params).then((res) => res.data);
};
