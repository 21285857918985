import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  chainId: undefined,
  accounts: [],
  pathName: '',
  currentConnector: undefined,
};

const configSlice = createSlice({
  name: 'config',
  initialState: INIT_STATE,
  reducers: {
    setChainId(state, action) {
      state.chainId = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setPathName(state, action) {
      state.pathName = action.payload;
    },
    setCurrentConnector(state, action) {
      state.currentConnector = action.payload;
    },
  },
});

export const {
  setChainId,
  setAccounts,
  setPathName,
  setCurrentConnector
} = configSlice.actions;

export default configSlice.reducer;
