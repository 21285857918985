import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { CONNECTORS } from '../../../../utils/enums';
import ChooseWalletModal from './ChooseWalletModal';
import './ConnectState.scss';

const ConnectState = ({ handleConnect }: any) => {
  const [connector, setConnector] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (connector && handleConnect && openModal) {
      handleConnect(connector);
      setOpenModal(false);
    }
    return () => {
      setConnector(null);
    };
  }, [connector, handleConnect, openModal]);

  const handleActionConnect = useCallback(() => {
    if (window?.ethereum?.isAvacus) {
      handleConnect(CONNECTORS.injected);
    } else {
      setOpenModal(!openModal);
    }
  }, [handleConnect, openModal]);

  return (
    <>
      <div className="d-flex align-items-center">
        <Button
          type="button"
          color="primary"
          onClick={handleActionConnect}
          className="mx-0 ml-2 btn-connect-wallet"
          size="sm"
        >
          <div className="text-btn">
            {window?.ethereum?.isAvacus ? 'Connect Avacus' : 'Connect Wallet'}
          </div>
        </Button>
      </div>
      {openModal && (
        <ChooseWalletModal
          setConnector={setConnector}
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
        />
      )}
    </>
  );
};
export default ConnectState;
