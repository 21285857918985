import { UnsupportedChainIdError } from '@web3-react/core';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import './index.css';

const getMessage = (error) => {
  switch (error) {
    case 'NoBscProviderError':
    case 'NoEthereumProviderError':
      return (
        <div className="text-body-2">
          Can't connect to the{' '}
          <a
            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
            target="_blank"
            className="text-primary"
            rel="noreferrer"
          >
            Metamask Extension
          </a>
          . Please make sure that you installed the extension.
        </div>
      );
    case 'UnauthorizedError':
      return <div className="text-body-2">Please login to your wallet first.</div>;
    case '-32002':
      return (
        <div className="text-body-2">
          Your request already pending for this website. Please check on Metamask.
        </div>
      );
    default:
      return (
        <div className="text-body-2">
          Can't connect to the wallet. Please make sure that you logged in and selected the right
          network.
        </div>
      );
  }
};

const ConnectErrorState = ({ error, connect, disconnect, switchNetwork, connectorMounted }) => {
  const errorName = error?.name;
  const message = getMessage(errorName);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (error) {
      setModal(true);
    }
  }, [error]);

  const retry = async () => {
    if (error instanceof UnsupportedChainIdError || error?.name === 'ChainUnsupportedError') {
      switchNetwork();
    } else {
      connect(connectorMounted);
    }
  };

  const toggle = async () => {
    disconnect();
    setModal(false);
  };
  return (
    <Modal isOpen={modal} toggle={toggle} onClosed={toggle} centered size="sm">
      <ModalBody>{message}</ModalBody>
      <ModalFooter className="mb-2">
        {errorName !== 'NoEthereumProviderError' && (
          <Button type="button" size="sm" color="primary" onClick={retry}>
            Switch Network
          </Button>
        )}
        <Button type="button" size="sm" color="secondary" className="mx-2" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConnectErrorState;
