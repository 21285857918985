import storage from 'redux-persist/lib/storage';

import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import rootReducer from './reducers';

import { loadChainsRequest } from './slices/chainSlice';
import { loadRatesRequest } from './slices/rateSlice';
import { loadNetworksRequest } from './slices/networkSlice';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['config', 'auth'], //Things u want to persist
  //   blacklist: [], //Things u dont
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// It will dispatch when start the site
store.dispatch(loadRatesRequest());
store.dispatch(loadChainsRequest());
store.dispatch(loadNetworksRequest());

export default store;
