import { KNOWN_CHAINS } from '../utils/chains';
import { CURRENT_NETWORK_NAME } from '../utils/constants';

const useSupportedNetworks = () => {
  const supportedNetworks = [];
  const supportedChainIds = [];
  KNOWN_CHAINS.forEach((el) => {
    if (el.networks && el.networks.includes(CURRENT_NETWORK_NAME)) {
      const isSupportedFund = el.lendingFunds.includes(CURRENT_NETWORK_NAME);
      supportedNetworks.push({...el, isSupportedFund});
      if (el.isSupported) supportedChainIds.push(el.id);
    }
  });

  return { supportedNetworks, supportedChainIds };
};

export default useSupportedNetworks;
