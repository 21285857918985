import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from './redux/store';
import persistStore from 'redux-persist/lib/persistStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Web3ContextProvider } from './components/Web3React/Web3ContextProvider';

const persistor = persistStore(store);

if (process.env.REACT_APP_ENV === 'prod') {
  console.warn = () => {};
  console.log = () => {};
}

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_RATE, //lower the value in production
});

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3ContextProvider>
          <App />
        </Web3ContextProvider>
      </PersistGate>
    </Provider>
    <ToastContainer
      limit={3}
      autoClose={3000}
      position="top-right"
      hideProgressBar
      draggable
      pauseOnHover
      closeOnClick
      newestOnTop
    />
  </>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
