import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  Navbar,
  NavbarText,
  NavItem,
} from 'reactstrap';
import { getTotalLendingApi } from '../../../apis/statsApis';
import useQuery from '../../../hooks/useQuery';
import useSwitchOrAddNetwork from '../../../hooks/useSwitchOrAddNetwork';
import { updateCurrentChain } from '../../../redux/slices/chainSlice';
import { setPathName } from '../../../redux/slices/configSlice';
import store from '../../../redux/store';
import { DEFAULT_FIAT } from '../../../utils/constants';
import { REDUCERS, SCREENS } from '../../../utils/enums';
import { formatFiatAmount } from '../../../utils/formats';
import Account from '../../Commons/ConnectWallet';
import EmailNotification from '../../EmailNotification';
import './Navbar.scss';
import SettingGasFeeDropdown from './SettingGasFeeDropdown';
import { ConnectionType, switchNetwork } from '../../../web3-libs/connections';

const CommonNavbar = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const switchOrAddNetworkOnMetamask = useSwitchOrAddNetwork();
  const chains = useSelector((state) => state.chain);
  const currentChain = useSelector((state) => state.chain.currentChain);
  const [totalLending, setTotalLending] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenMenu, toggleMenu] = useState(false);
  const [openModalWrongNetwork, setOpenModalWrongNetwork] = useState(false);
  const [blockchain, setBlockchain] = useState();
  const [chainDashboard, setChainDashboard] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { account } = useWeb3React();
  const connectType = useSelector((state) => state[REDUCERS.CONFIG].currentConnector);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const modalToggle = () => {
    setOpenModalWrongNetwork(false);
  };

  useEffect(() => {
    if (location && location.pathname.includes(SCREENS.DASHBOARD)) {
      const paths = location.pathname.replace(`/${SCREENS.DASHBOARD}/`, '').split('/');
      blockchain !== paths[0] && setBlockchain(paths[0]);
    } else {
      setBlockchain(null);
      setChainDashboard();
      setOpenModalWrongNetwork(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (currentChain && currentChain.id) {
      const params = {
        blockchain: currentChain.blockchainName,
        fiat: DEFAULT_FIAT,
      };
      getTotalLending(params);
    }
  }, [currentChain]);

  useEffect(() => {
    if (!account) {
      // setSwitchNetworkFirstTime(false);
      setOpenModalWrongNetwork(false);
    }
    if (account && currentChain && blockchain && blockchain !== currentChain.blockchainName) {
      const linkChain = chains.data.find((el) => blockchain === el.blockchainName);
      setChainDashboard(linkChain);
      setOpenModalWrongNetwork(true);
    } else {
      setOpenModalWrongNetwork(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, blockchain, currentChain]);

  const getTotalLending = async (params) => {
    try {
      const data = await getTotalLendingApi(params);
      setTotalLending(+data.total_lending);
    } catch (error) {
      setTotalLending(0);
    }
  };

  const switchOrAddNetwork = useCallback(
    async (chain, connectType) => {
      try {
        if (connectType === ConnectionType.INJECTED) {
          const result = await switchOrAddNetworkOnMetamask(chain.id);
          if (typeof result === 'boolean' && result) {
            if (query.get('network')) {
              query.set('network', chain.blockchainName);
              history.replace({ search: query.toString() });
            }
            localStorage.setItem('currentChainID', JSON.stringify(chain.id));
            store.dispatch(updateCurrentChain(chain));
            openModalWrongNetwork && setOpenModalWrongNetwork(false);
          }
        } else {
          await switchNetwork(chain.id, connectType);
          if (query.get('network')) {
            query.set('network', chain.blockchainName);
            history.replace({ search: query.toString() });
          }
          localStorage.setItem('currentChainID', JSON.stringify(chain.id));
          store.dispatch(updateCurrentChain(chain));
        }
      } catch (error) {
        setErrorMessage(`Your request already pending for this website. Please check your wallet.`);
      }
    },
    [history, openModalWrongNetwork, query, switchOrAddNetworkOnMetamask]
  );

  const changeChain = useCallback(
    async (chain) => {
      if (chain.id === currentChain.id || !chain.isSupported) return;
      if (account && connectType) {
        switchOrAddNetwork(chain, connectType);
      } else {
        localStorage.setItem('currentChainID', JSON.stringify(chain.id));
        store.dispatch(updateCurrentChain(chain));
        if (query.get('network')) {
          query.set('network', chain.blockchainName);
          history.replace({ search: query.toString() });
        }
      }
    },
    [account, connectType, currentChain.id, history, query, switchOrAddNetwork]
  );

  const backHome = () => {
    history.replace('/');
  };

  const isActiveDashboard = () => {
    if (location.pathname === `/${SCREENS.DASHBOARD}`) return true;
    const arrPath = location.pathname
      .replace(`/${SCREENS.DASHBOARD}/`, '')
      .split('/')
      .filter((el) => el);
    const chainObject = chains.data.find(
      (el) => el.isSupported && el.blockchainName === arrPath[0]
    );
    if (
      chainObject &&
      location.pathname.includes(`/${SCREENS.DASHBOARD}/${chainObject.blockchainName}`) &&
      (arrPath.length === 1 ||
        (account && arrPath[1].toLocaleLowerCase() === account.toLocaleLowerCase()))
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="">
      <Container fluid={true}>
        <Navbar expand="lg" className="fixed-top">
          <div className="common-navbar px-3">
            <NavbarText className="common-navbar__logo py-0 h-100">
              <img
                src="/images/icons/ic-menu.svg"
                alt="Menu Icon"
                className="common-navbar__menu-icon mr-1 d-block d-md-none"
                onClick={() => toggleMenu(!isOpenMenu)}
              />
              <Link to="/" className="d-none d-md-block">
                <img src="/images/ketos-logo.svg" alt="logo" />
              </Link>
              <Link to="/" className="d-block d-md-none">
                <img src="/images/ketos-sm-logo.svg" alt="logo" />
              </Link>
            </NavbarText>
            <Nav
              navbar
              className={`common-navbar__menu-wrapper d-md-flex  ${
                isOpenMenu ? 'd-block' : 'd-none'
              }`}
            >
              {account && currentChain && currentChain.blockchainName && (
                <NavItem>
                  <Link
                    to={`/${SCREENS.DASHBOARD}`}
                    className={`nav-menu nav-link px-0 ${
                      isActiveDashboard() ? 'link-active text-label-1-bold' : 'text-label-1'
                    }`}
                    onClick={() => toggleMenu(!isOpenMenu)}
                  >
                    Dashboard
                  </Link>
                </NavItem>
              )}
              <NavItem>
                <Link
                  to={`/`}
                  className={`nav-menu nav-link ${
                    location.pathname === '/' ? 'link-active text-label-1-bold' : 'text-label-1'
                  }`}
                  onClick={() => toggleMenu(!isOpenMenu)}
                >
                  Lend
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to={`/${SCREENS.CREATE_OFFER}`}
                  className={`nav-menu nav-link px-0 ${
                    location.pathname === '/create-offer'
                      ? 'link-active text-label-1-bold'
                      : 'text-label-1'
                  }`}
                  onClick={() => {
                    store.dispatch(setPathName(`${location.pathname}-${location.key}`));
                    toggleMenu(!isOpenMenu);
                  }}
                >
                  Borrow
                </Link>
              </NavItem>
              {currentChain?.isSupportedFund && (
                <NavItem>
                  <Link
                    to={`/${SCREENS.LENDING_FUND}`}
                    className={`nav-menu nav-link px-0 ${
                      location.pathname === `/${SCREENS.LENDING_FUND}`
                        ? 'link-active text-label-1-bold'
                        : 'text-label-1'
                    }`}
                    style={{ minWidth: 100 }}
                    onClick={() => {
                      store.dispatch(setPathName(`${location.pathname}-${location.key}`));
                      toggleMenu(!isOpenMenu);
                    }}
                  >
                    Lending Fund
                  </Link>
                </NavItem>
              )}
            </Nav>
            <Nav navbar className="d-flex align-items-center flex-row w-100 justify-content-end">
              <NavItem className="d-none d-md-flex pr-0 text-label-1-bold">
                <span className="text-neutrals-middle-2">Total Lending: </span>
                <span className="ml-1 text-neutrals-light-5">
                  {formatFiatAmount(totalLending ?? 0, DEFAULT_FIAT)}
                </span>
              </NavItem>
              {account && (
                <NavItem className="pr-0">
                  <EmailNotification />
                </NavItem>
              )}
              {currentChain.blockchainName === 'astar' && (
                <NavItem className="pr-0">
                  <SettingGasFeeDropdown currentChain={currentChain} />
                </NavItem>
              )}
              <NavItem className="pr-0">
                <Account />
              </NavItem>
              <NavItem className="pr-0">
                <Dropdown className="nav-chain" isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret className="chain-btn">
                    <img
                      className="icon-network"
                      src={`/images/chains/${currentChain?.blockchainName}.svg`}
                      alt={currentChain ? '' : 'Network Chain'}
                    />
                    <div className="d-none d-md-flex ml-2">{currentChain?.fullName}</div>
                  </DropdownToggle>
                  <DropdownMenu right={true} className="drop-chains">
                    {chains.data.map((el, id) => (
                      <DropdownItem
                        className="network"
                        key={id}
                        onClick={(e) => {
                          e.preventDefault();
                          changeChain(el);
                        }}
                        disabled={!el.isSupported}
                      >
                        <img
                          className="mr-2 icon-network"
                          src={`/images/chains/${el?.blockchainName}.svg`}
                          alt={''}
                        />
                        {el?.fullName}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>
          </div>
        </Navbar>
      </Container>
      <Modal
        isOpen={openModalWrongNetwork}
        toggle={modalToggle}
        centered
        size="sm"
        backdrop={'static'}
      >
        <ModalBody>
          <div className="text-body-2">
            Oops, your current network doesn’t match with dashboard’s. Please switch to a new one by
            using Metamask. If not, it’s will turn to Home page.
          </div>
          {errorMessage && <div className="text-caption-bold text-danger">{errorMessage}</div>}
        </ModalBody>
        <ModalFooter className="mb-2 d-flex align-items-center justify-content-end">
          <Button
            type="button"
            size="sm"
            color="primary"
            className="mx-2"
            onClick={() => switchOrAddNetworkOnMetamask(chainDashboard)}
          >
            Switch Network
          </Button>
          <Button
            type="button"
            size="sm"
            color="default"
            outline
            className="mx-2"
            onClick={() => {
              modalToggle();
              backHome();
              console.log('onClick Close');
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CommonNavbar;
