import { combineReducers } from 'redux';

import configReducer from './slices/configSlice';
import rateReducer from './slices/rateSlice';
import chainReducer from './slices/chainSlice';
import authReducer from './slices/authSlice';
import networkReducer from './slices/networkSlice';
import fundReducer from './slices/fundSlice';

const rootReducer = combineReducers({
  chain: chainReducer,
  rate: rateReducer,
  config: configReducer,
  auth: authReducer,
  network: networkReducer,
  fund: fundReducer,
});

export default rootReducer;
