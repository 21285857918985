import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';

import { Connection, ConnectionType } from './connections';

export function buildInjectedConnector() {
  const [web3MetamaskWallet, web3MetamaskWalletHooks] = initializeConnector<MetaMask>(
    (actions: any) =>
      new MetaMask({
        actions,
        onError: (error: Error) => {
          return error;
        },
      })
  );
  const injectedConnection: Connection = {
    connector: web3MetamaskWallet,
    hooks: web3MetamaskWalletHooks,
    type: ConnectionType.INJECTED,
  };

  return injectedConnection;
}
