const signMessage = async (provider, account, message) => {
  return provider
    .getSigner(account)
    .signMessage(message)
    .then((signature) => signature)
    .catch((error) => {
      throw error;
    });
};

export default signMessage;
