// CONSTANT GO HERE
export const DEFAULT_FIAT = 'usd';
export const DEFAULT_CRYPTO_FIAT = 'USDC';
export const DEFAULT_CONNECTOR = 'injected';
export const MORTGAGE_TERMS = [
  {
    cycleNo: 1,
    days: 7,
  },
  {
    cycleNo: 3,
    days: 21,
  },
  {
    cycleNo: 5,
    days: 35,
  },
  {
    cycleNo: 10,
    days: 70,
  },
];

export const BASE_URL = {
  public: `v1/`,
  public_v2: `v2/`,
  public_v3: `v3/`,
};
export const MAX_TAG_NUMBER = 5;

export const ERC_721 = 'erc721';
export const ERC_1155 = 'erc1155';
export const LENDING_TERM = 7;
// img
export const ICON_SUCCESS = '/images/icons/success.svg';
export const ICON_ERROR = '/images/icons/error.svg';
export const NO_DATA = '/images/no-data.png';
export const NFT_PLATFORMS = [
  'opensea',
  'rarible',
  'etherscan',
  'bscscan',
  'tofunft',
  'polygonscan',
];
export const NETWORKS = ['Ethereum', 'Binance Smart Chain'];
export const TIME_CALL_FUNCTION_AGAIN = 5000;
export const FORMAT_DATE = 'dd-MM-yyyy HH:mm';
export const SECONDS_TIME_PER_DAY = 86400;
export const AFTER_DAYS_CAN_CLAIM_NFT = 30;
export const LENDER_RATE = {
  min: 6,
  max: 72,
};
export const RATE_DECIMALS = 10000;
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const MAX_MORTGAGE_TERM = 52;
export const MINIMUM_AMOUNT_PROD = 1;
export const VERIFIED_COLLECTION_ADDRESS = [
  '0x6ac87169d3f04c68420bdd4d4242ad5c6749b69a',
  '0xb08afd5aadafaa360f03a8d9fc2f27bf8211fa5e',
  '0x7ae0fb179815c4fec54905ea90926be5774a7dd0',
];
export const CURRENT_NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME || 'testnet';
export const DISQUS_SHORT_NAME = process.env.REACT_APP_ENV === 'prod' ? 'ketos-2' : 'ketos-3';
export const DISQUS_CONFIG_URL =
  process.env.REACT_APP_ENV === 'prod' ? 'https://ketos.app' : 'https://alpha.ketos.app';
