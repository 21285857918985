import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonNavbar from './components/Layouts/Navbar';
import Footer from './components/Layouts/Footer';
import { REDUCERS, SCREENS } from './utils/enums';
import LoadingFullScreen from './components/Commons/Loading/LoadingFullScreen';

import { updatePawnShopContract } from './redux/slices/networkSlice';
import { getPawnShopContract } from './helpers/offer-helper';
import { getExchangeRatesApi } from './apis/rateApis';
import { loadRatesSuccess } from './redux/slices/rateSlice';

import store from './redux/store';

const HomePage = lazy(() => import('./features/Home'));
const CreateOfferPage = lazy(() => import('./features/Offer/pages/CreateOffer'));
const OfferDetailPage = lazy(() => import('./features/Offer/pages/OfferDetail'));
const NotFoundPage = lazy(() => import('./components/Commons/NotFound'));
const MyProfilePage = lazy(() => import('./features/Profile'));
const UnsubscribeEmailPage = lazy(() => import('./features/UnsubscribeEmail'));
const LendingFundHomePage = lazy(() => import('./features/LendingFund/pages/LendingFundHome'));
const LendingFundDetailPage = lazy(() => import('./features/LendingFund/pages/LendingFundDetail'));
const CreateLendingFundPage = lazy(() => import('./features/LendingFund/pages/CreateLendingFund'));
const UpdateLendingFundPage = lazy(() => import('./features/LendingFund/pages/UpdateLendingFund'));

function App() {
  const currentChain = useSelector((state) => state.chain.currentChain);
  const networks = useSelector((state) => state[REDUCERS.NETWORK].networks);

  useEffect(() => {
    const reFetchRates = async () => {
      try {
        const result = await getExchangeRatesApi();
        store.dispatch(loadRatesSuccess(result));
      } catch (e) {}
    };
    const idInterval = setInterval(() => reFetchRates(), 300000);
    return () => idInterval && clearInterval(idInterval);
  }, []);

  useEffect(() => {
    getCurrentNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networks, currentChain]);

  const getCurrentNetwork = () => {
    const pawnShopContract = getPawnShopContract(currentChain, networks);
    store.dispatch(updatePawnShopContract(pawnShopContract));
  };

  return (
    <Router>
      <CommonNavbar />
      <div style={{ minHeight: 'calc(100vh - 190px)' }}>
        <Switch>
          <Suspense fallback={<LoadingFullScreen />}>
            <Route exact path="/" component={HomePage} />
            <Route path={`/${SCREENS.CREATE_OFFER}`} component={CreateOfferPage} exact />
            <Route path={`/${SCREENS.OFFER_DETAIL}/:id`} exact component={OfferDetailPage} />
            <Route path={`/${SCREENS.DASHBOARD}`} exact component={MyProfilePage} />
            <Route path={`/${SCREENS.DASHBOARD}/:blockchain`} component={MyProfilePage} />
            <Route path={`/users/:address/unsubscribe`} component={UnsubscribeEmailPage} />
            <Route path={`/${SCREENS.LENDING_FUND}`} component={LendingFundHomePage} exact />
            <Route path={`/${SCREENS.LENDING_FUND}/:id`} component={LendingFundDetailPage} exact />

            <Route
              path={`/${SCREENS.CREATE_LENDING_FUND}`}
              component={CreateLendingFundPage}
              exact
            />
            <Route
              path={`/${SCREENS.LENDING_FUND}/:id/update`}
              component={UpdateLendingFundPage}
              exact
            />
            <Route path={`/${SCREENS.NOT_FOUND}`} component={NotFoundPage} />
          </Suspense>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
