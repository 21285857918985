import axios from 'axios';
import { BASE_URL } from '../utils/constants';

export const getExchangeRatesApi = () => {
  return axios({
    baseURL: `${process.env.REACT_APP_CAMDO_API_DOMAIN}/2/${process.env.REACT_APP_CAMDO_API_NAME}`,
    timeout: 30000,
    method: 'get',
    headers: {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
    },
    url: `${BASE_URL.public}exchange-rates`,
  }).then((res) => res.data.data);
};
