import React, { useCallback, useEffect, useState } from 'react';
import ConnectState from './ConnectState';
import { useWeb3React } from '@web3-react/core';
import AccountState from './AccountState';
import useNetworkListener from '../../../hooks/useNetworkListener';
import useSwitchOrAddNetwork from '../../../hooks/useSwitchOrAddNetwork';
import { useSelector } from 'react-redux';
import { CONNECTORS, REDUCERS } from '../../../utils/enums';
import { handleShowMessage } from '../../../helpers/error-helper';
import ConnectErrorState from './ConnectErrorState';
import useConnectWallet from '../../../hooks/useConnectWallet';

const Account = () => {
  const { isActive } = useWeb3React();
  const [callConnect, setCallConnect] = useState(false);
  const currentChainId = useSelector((state) => state[REDUCERS.CHAIN].currentChainID);
  const { chainMetaMaskId } = useNetworkListener();
  const switchOrAddNetwork = useSwitchOrAddNetwork();
  const [showError, setShowError] = useState(false);
  const { connectWallet, disconnectWallet, connectorMounted, error } = useConnectWallet();

  const injectedConnect = useCallback(async () => {
    if (!window.ethereum) {
      window.open('https://metamask.io/download/', '_blank');
    } else {
      setCallConnect(true);
      try {
        if (chainMetaMaskId === currentChainId) {
          await connectWallet(CONNECTORS.injected);
        } else {
          const result = await switchOrAddNetwork(Number(currentChainId));
          if (result === true) {
            await connectWallet(CONNECTORS.injected);
          } else {
            setCallConnect(false);
          }
        }
      } catch (ex) {
        console.log('error', ex);
        setCallConnect(false);
      }
    }
  }, [chainMetaMaskId, connectWallet, currentChainId, switchOrAddNetwork]);

  const handleConnect = useCallback(
    async (connector) => {
      if (connector === CONNECTORS.injected) {
        await injectedConnect();
      } else if (connector === CONNECTORS.walletconnect) {
        await connectWallet(connector);
      }
    },
    [connectWallet, injectedConnect]
  );

  const handleDisconnect = useCallback(() => {
    setCallConnect(false);
    setShowError(false);
    localStorage.removeItem('isConnected');
    localStorage.removeItem('connector');
    localStorage.removeItem('walletconnect');
    disconnectWallet();
  }, [disconnectWallet]);

  const connectOnReload = useCallback(
    async (lastConnector) => {
      setCallConnect(true);
      try {
        if (lastConnector === CONNECTORS.injected) {
          await connectWallet(lastConnector);
        } else {
          handleDisconnect();
        }
      } catch (ex) {
        handleShowMessage('error', ex);
        setCallConnect(false);
        handleDisconnect();
      }
    },
    [connectWallet, handleDisconnect]
  );

  const handleChainUnsupportedError = useCallback(async () => {
    try {
      const lastChainId = localStorage.getItem('currentChainID');
      const result = await switchOrAddNetwork(Number(lastChainId));
      if (result !== true) {
        handleDisconnect();
      }
      setShowError(false);
    } catch (ex) {
      console.log(`error: ${ex}`);
      handleShowMessage('error', ex);
      handleDisconnect();
      setShowError(false);
    }
  }, [handleDisconnect, switchOrAddNetwork]);

  useEffect(() => {
    if (error) {
      handleDisconnect();
      setCallConnect(false);
    } else {
      setShowError(false);
    }
  }, [callConnect, error, handleDisconnect]);

  useEffect(() => {
    if (isActive) {
      setCallConnect(false);
      setShowError(false);
    }
  }, [isActive]);

  const checkWalletIsConnected = useCallback(async () => {
    const isConnected = localStorage.getItem('isConnected') === 'true';
    const lastConnector = localStorage.getItem('connector') ?? null;
    if (!isActive && isConnected && lastConnector === CONNECTORS.injected) {
      const { ethereum } = window;
      if (ethereum) {
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        if (accounts.length <= 0) handleDisconnect();
        if (!isActive) {
          connectOnReload(lastConnector);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkWalletIsConnected();
  }, [checkWalletIsConnected]);

  if (isActive) {
    return (
      <div>
        <AccountState disconnect={handleDisconnect} />
      </div>
    );
  }
  if (showError && error) {
    return (
      <ConnectErrorState
        error={error}
        disconnect={handleDisconnect}
        switchNetwork={handleChainUnsupportedError}
        connect={handleConnect}
        connectorMounted={connectorMounted}
      />
    );
  }
  return (
    <div>
      <ConnectState handleConnect={handleConnect} />
    </div>
  );
};

export default Account;
