import axios from 'axios';
import queryString from 'query-string';
import { getHTTPErrorMessage } from '../helpers/error-helper';
import store from '../redux/store';

// Set up default config for http requests here
const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_CAMDO_API_DOMAIN}/2/${process.env.REACT_APP_CAMDO_API_NAME}`,
  // baseURL: `${process.env.REACT_APP_CAMDO_API_DOMAIN}`,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.defaults.timeout = 60000;

axiosClient.interceptors.request.use((config) => {
  const auth = store.getState().auth;
  const token = auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (error.code === 'ECONNABORTED') {
      // eslint-disable-next-line no-throw-literal
      throw '';
    } else {
      // Handle errors
      const message = getHTTPErrorMessage(error);
      throw message;
    }
  }
);

export default axiosClient;
