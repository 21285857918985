import { CURRENT_NETWORK_NAME } from './constants';

const ETH = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC = {
  name: 'Matic Token',
  symbol: 'MATIC',
  decimals: 18,
};

const BNB = {
  name: 'BNB',
  symbol: 'BNB',
  decimals: 18,
};

export const KNOWN_CHAINS = new Map([
  [
    56,
    {
      id: 56,
      nativeCurrency: BNB,
      type: 'main',
      fullName: 'BNB Chain',
      shortName: 'BNB Chain',
      explorerUrl: `https://bscscan.com`,
      networks: ['mainnet'],
      blockchainName: 'bsc',
      isSupported: true,
      subgraph: 'https://api.thegraph.com/subgraphs/name/wakumo/ketos-bsc',
      lendingFunds: ['mainnet'],
    },
  ],
  [
    1,
    {
      id: 1,
      nativeCurrency: ETH,
      type: 'main',
      fullName: 'Ethereum',
      shortName: 'Mainnet',
      explorerUrl: `https://etherscan.io`,
      networks: ['mainnet'],
      blockchainName: 'ethereum',
      isSupported: false,
      subgraph: '',
      lendingFunds: ['testnet'],
    },
  ],
  [
    5,
    {
      id: 5,
      nativeCurrency: ETH,
      type: 'main',
      fullName: 'Ethereum Goerli',
      shortName: 'Goerli',
      explorerUrl: `https://goerli.etherscan.io/`,
      networks: ['testnet'],
      blockchainName: 'ethereum',
      isSupported: false,
      subgraph: 'https://api.thegraph.com/subgraphs/name/wakumo/ketos-rinkeby',
      lendingFunds: ['testnet'],
    },
  ],
  [
    97,
    {
      id: 97,
      nativeCurrency: BNB,
      type: 'main',
      fullName: 'BNB Testnet',
      shortName: 'BNB Testnet',
      explorerUrl: `https://testnet.bscscan.com`,
      networks: ['testnet'],
      blockchainName: 'bsc',
      isSupported: true,
      subgraph: 'https://rpc.chapel.g.wakumo.net/subgraphs/name/wakumo/ketos-chapel',
      lendingFunds: ['testnet'],
    },
  ],
  [
    137,
    {
      id: 137,
      nativeCurrency: MATIC,
      type: 'matic',
      fullName: 'Polygon',
      shortName: 'Matic',
      explorerUrl: `https://polygonscan.com`,
      networks: ['mainnet'],
      isSupported: true,
      blockchainName: 'polygon',
      subgraph: '',
      lendingFunds: [],
    },
  ],
  [
    80001,
    {
      id: 80001,
      nativeCurrency: MATIC,
      type: 'mumbai',
      fullName: 'Polygon Testnet',
      shortName: 'Mumbai',
      explorerUrl: `https://mumbai.polygonscan.com`,
      networks: ['testnet'],
      isSupported: false,
      blockchainName: 'polygon',
      subgraph: 'https://api.thegraph.com/subgraphs/name/wakumo/ketos-mumbai',
      lendingFunds: ['testnet'],
    },
  ]
]);

export const ADDED_CHAINS = [
  {
    chainId: '0x38', //56
    chainName: KNOWN_CHAINS.get(56).fullName,
    nativeCurrency: BNB,
    rpcUrls: ['https://bsc-dataseed1.ninicoin.io/'],
    blockExplorerUrls: [KNOWN_CHAINS.get(56).explorerUrl],
  },
  {
    chainId: '0x61', // 97
    chainName: KNOWN_CHAINS.get(97).fullName,
    nativeCurrency: BNB,
    rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
    blockExplorerUrls: [KNOWN_CHAINS.get(97).explorerUrl],
  },
  {
    chainId: '0x89', //137
    chainName: KNOWN_CHAINS.get(137).fullName,
    nativeCurrency: MATIC,
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/', 'https://polygon-rpc.com/'],
    blockExplorerUrls: [KNOWN_CHAINS.get(137).explorerUrl],
  },
  {
    chainId: '0x13881', //80001
    chainName: KNOWN_CHAINS.get(80001).fullName,
    nativeCurrency: MATIC,
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com', 'https://rpc-mumbai.matic.today/'],
    blockExplorerUrls: [KNOWN_CHAINS.get(80001).explorerUrl],
  },
  {
    chainId: '0x5', // 5
    chainName: KNOWN_CHAINS.get(5).fullName,
    nativeCurrency: ETH,
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    blockExplorerUrls: [KNOWN_CHAINS.get(5).explorerUrl],
  },
];

export const NETWORK_CHAINS = Array.from(KNOWN_CHAINS.values()).reduce((result, chain) => {
  if (chain.networks && chain.isSupported && chain.networks.includes(CURRENT_NETWORK_NAME)) {
    result.push(chain);
  }
  return result;
}, []);

export const NETWORK_CHAIN_IDS = NETWORK_CHAINS.reduce((result, chain) => {
  chain.id && result.push(chain.id);
  return result;
}, []);
