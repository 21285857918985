import { createSlice } from '@reduxjs/toolkit';
import { REDUCERS } from '../../utils/enums';

const INITIAL_STATE = {
  address: undefined,
  name: undefined,
  username: undefined,
  token: undefined,
  email: undefined,
  avatar: undefined,
  loading: false,
  openDropEmail: false,
};

const authSlice = createSlice({
  name: REDUCERS.AUTH,
  initialState: INITIAL_STATE,
  reducers: {
    authUpdateInfo(state, action) {
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.address = action.payload.address;
      state.username = action.payload.username;
      state.avatar = action.payload.avatar;
      state.loading = false;
    },
    authUpdateLoadingInfo(state, action) {
      state.loading = action.payload;
    },
    authUpdateOpenDropEmail(state, action) {
      state.openDropEmail = action.payload;
    },
    authResetInfo(state) {
      state.name = INITIAL_STATE.name;
      state.token = INITIAL_STATE.token;
      state.email = INITIAL_STATE.email;
      state.address = INITIAL_STATE.address;
      state.username = INITIAL_STATE.username;
      state.avatar = INITIAL_STATE.avatar;
      state.loading = INITIAL_STATE.loading;
      state.openDropEmail = INITIAL_STATE.openDropEmail;
    },
  },
});

export const { authResetInfo, authUpdateInfo, authUpdateLoadingInfo, authUpdateOpenDropEmail } =
  authSlice.actions;

export default authSlice.reducer;
