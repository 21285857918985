import { toast } from 'react-toastify';

const getHTTPErrorMessage = (exception) => {
  let message;
  if (exception) {
    if (exception.response && exception.response.data) {
      if (exception.response.data.message && typeof exception.response.data.message === 'string') {
        message = exception.response.data.message;
      } else if (exception.response.data.error) {
        if (typeof exception.response.data.error === 'string')
          message = exception.response.data.error;
        else if (exception.response.data.error.message) {
          message = exception.response.data.error.message;
        }
      }
    }
  }
  return message ? message : 'server_error';
};

const handleShowMessage = (type, message) => {
  switch (type) {
    case 'error':
      if (typeof message === 'string') {
        if (message.includes('_')) message = message.replace('_', ' ');
        toast.error(message);
      } else {
        const errorMessage = message?.message || message?.data?.message;
        if (errorMessage && typeof errorMessage === 'string') toast.error(errorMessage);
        else toast.error('Opps something went wrong!');
      }
      break;
    case 'warning':
      toast.warning(message);
      break;
    case 'success':
      toast.success(message);
      break;
    default:
      break;
  }
};

export { getHTTPErrorMessage, handleShowMessage };
